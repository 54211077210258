import { Link } from 'react-router-dom'

const Nav = () => {

  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container">
          <Link className="navbar-brand" to="">
            <img src="codepx_logo.svg" width="96" />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              {/*<li className="nav-item">*/}
              {/*  <Link to={ '/landing-pages'} className="nav-link">Landing Pages</Link>*/}
              {/*</li>*/}
              <li className="nav-item">
                <Link to={ '/editor'} className="nav-link">Editor</Link>
              </li>
              <li className="nav-item">
                <Link to={'/canvas'} className="nav-link">Canvas</Link>
              </li>
              <li className="nav-item">
                <Link to={'/boilerplates'} className="nav-link">Boilerplates</Link>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Colors
                </a>
                <ul className="dropdown-menu">
                  <li><Link className="dropdown-item" to={'colors'}>Colors</Link></li>
                  <li><Link className="dropdown-item" to={'gradient-colors'}>Gradient Colors</Link></li>
                  <li><Link className="dropdown-item" to={'color-picker'}>Color Picker</Link></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Nav
