import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

const Home = () => {

    useEffect(() => {

    }, [])

    return (
        <div>
            <section className="hero-section text-center">
                <div className="container">
                    <h1 className="display-6 fade-in">Crafting Future-Ready Software Solutions</h1>
                    <p className="lead fade-in mt-3">Innovate, Elevate and Deliver</p>
                </div>
            </section>
            <section id="services" className="py-5">
                <div className="container">
                    <div className="row d-flex" data-aos="fade-up">
                        <div className="col-md-4 drop-in mb-4" data-aos-delay="100">
                            <div className="card text-center p-4 h-100">
                                <div className="card-body">
                                    <h3 className="mb-4">Application Development</h3>
                                    <p>
                                        Leverage cutting-edge technology with our bespoke application development services. We create seamless, high-performance apps tailored to your business needs, ensuring optimal functionality and user experience.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 drop-in-2 mb-4" data-aos="fade-up" data-aos-delay="200">
                            <div className="card text-center p-4 h-100">
                                <div className="card-body">
                                    <h3 className="mb-4">Website Design</h3>
                                    <p>
                                        Transform your online presence with our creative and responsive website design services. We craft visually stunning and user-friendly websites that leave a lasting impression.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 drop-in-3 mb-4" data-aos-delay="300">
                            <div className="card text-center p-4 h-100">
                                <div className="card-body">
                                    <h3 className="mb-4">E-commerce Solutions</h3>
                                    <p>
                                        Unlock the full potential of your business with our comprehensive e-commerce solutions. We provide cutting-edge scalable platforms, customizable designs, and seamless user experiences.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <p>
                    At CodePx, we transform your ideas into reality. Our expert team of developers is dedicated to delivering cutting-edge technology solutions that drive your business forward.
                    Partner with us to unlock your potential and achieve success. Let's build the future together.
                </p>
            </section>
            <div>

            </div>
        </div>
    )
}
export default Home
