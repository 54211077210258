const ReactApp = () => {
    const code = `
npm i react react-dom
npm i -D react-router-dom
npm i -D  vite eslint eslint-plugin-react eslint-plugin-react-hooks eslint-plugin-react-refresh globals
npm i -D @eslint/js @types/react @types/react-dom @vitejs/plugin-react
    `
    return (
        <>
            <h1>React App</h1>
            <h3>npm install</h3>
            <pre className="px-2 bg-body-secondary border">
                <code>
                    {code}
                </code>
            </pre>
        </>
    )
}
export default ReactApp