import { useEffect, useRef } from 'react';

const HtmlEditor = () => {
    const editorRef = useRef(null);

    const applyStyle = (style) => {
        const selection = window.getSelection();
        if (!selection.rangeCount) return;
        const range = selection.getRangeAt(0).cloneRange();
        const span = document.createElement('span');
        span.style.fontWeight = style === 'bold' ? 'bold' : 'normal';
        span.style.fontStyle = style === 'italic' ? 'italic' : 'normal';
        span.style.textDecoration = style === 'underline' ? 'underline' : 'none';
        range.surroundContents(span);
        selection.removeAllRanges();
        selection.addRange(range);
    };
    const insertTag = (tag) => {
        const selection = window.getSelection();
        if (!selection.rangeCount) return;
        const range = selection.getRangeAt(0).cloneRange();
        var _tag = document.createElement(tag);
        range.surroundContents(_tag);
        selection.removeAllRanges();
        selection.addRange(range);
    };

    const insertList = (ordered) => {
        const selection = window.getSelection();
        if (!selection.rangeCount) return;
        const range = selection.getRangeAt(0);
        const content = range.cloneContents();
        const divs = content.querySelectorAll('div');

        if (divs.length === 0) {
            return;
        }

        const ul = document.createElement(ordered ? 'ol' : 'ul');
        divs.forEach(div => {
            const li = document.createElement('li');
            li.innerHTML = div.innerHTML;
            ul.appendChild(li);
        });

        range.deleteContents();
        range.insertNode(ul);
    };

    const insertTable = () => {
        const selection = window.getSelection();
        if (!selection.rangeCount) return;
        const range = selection.getRangeAt(0);
        const table = document.createElement('table');
        table.style.width = '100%';
        table.className = "table border table-striped"
        const tbody = document.createElement('tbody');
        table.appendChild(tbody);
        for (let i = 0; i < 2; i++) {
            const row = document.createElement('tr');
            for (let j = 0; j < 2; j++) {
                const cell = document.createElement('td');
                cell.textContent = `Cell ${i + 1}, ${j + 1}`;
                row.appendChild(cell);
            }
            tbody.appendChild(row);
        }
        range.insertNode(table);
    };
    // Navigate table cell through tabs

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Tab') {
                const selection = window.getSelection();
                if (!selection.rangeCount) return;

                const range = selection.getRangeAt(0);
                const activeElement = range.startContainer;

                if (activeElement.nodeType === Node.TEXT_NODE) {
                    const cell = activeElement.parentElement.closest('td, th');

                    if (cell) {
                        e.preventDefault();
                        let nextCell = cell.nextElementSibling;

                        if (!nextCell) {
                            const row = cell.parentElement;
                            const table = row.parentElement;
                            const nextRow = row.nextElementSibling;

                            if (nextRow) {
                                nextCell = nextRow.cells[0];
                            } else {
                                const newRow = table.insertRow();
                                for (let i = 0; i < row.cells.length; i++) {
                                    const newCell = newRow.insertCell();
                                    newCell.textContent = 'New Cell';
                                }
                                nextCell = newRow.cells[0];
                            }
                        }

                        if (nextCell) {
                            const newRange = document.createRange();
                            newRange.setStart(nextCell, 0);
                            newRange.setEnd(nextCell, 0);
                            selection.removeAllRanges();
                            selection.addRange(newRange);
                            nextCell.focus();
                        }
                    }
                }
            }
        };

        const editor = editorRef.current;
        editor.addEventListener('keydown', handleKeyDown);

        return () => {
            editor.removeEventListener('keydown', handleKeyDown);
        };
    }, []);


    return (
        <div className="mt-1">
            <h1>HTML Editor</h1>
            <div className="btn-toolbar mb-3" role="toolbar">
                <div className="btn-group me-2" role="group">
                    <button type="button" className="btn btn-secondary" onClick={() => applyStyle('bold')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M8.21 13c2.106 0 3.412-1.087 3.412-2.823 0-1.306-.984-2.283-2.324-2.386v-.055a2.176 2.176 0 0 0 1.852-2.14c0-1.51-1.162-2.46-3.014-2.46H3.843V13zM5.908 4.674h1.696c.963 0 1.517.451 1.517 1.244 0 .834-.629 1.32-1.73 1.32H5.908V4.673zm0 6.788V8.598h1.73c1.217 0 1.88.492 1.88 1.415 0 .943-.643 1.449-1.832 1.449H5.907z" />
                        </svg>
                    </button>
                    <button type="button" className="btn btn-secondary" onClick={() => applyStyle('italic')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M7.991 11.674 9.53 4.455c.123-.595.246-.71 1.347-.807l.11-.52H7.211l-.11.52c1.06.096 1.128.212 1.005.807L6.57 11.674c-.123.595-.246.71-1.346.806l-.11.52h3.774l.11-.52c-1.06-.095-1.129-.211-1.006-.806z" />
                        </svg>
                    </button>
                    <button type="button" className="btn btn-secondary" onClick={() => applyStyle('underline')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M5.313 3.136h-1.23V9.54c0 2.105 1.47 3.623 3.917 3.623s3.917-1.518 3.917-3.623V3.136h-1.23v6.323c0 1.49-.978 2.57-2.687 2.57s-2.687-1.08-2.687-2.57zM12.5 15h-9v-1h9z" />
                        </svg>
                    </button>
                </div>
                <div className="btn-group me-2" role="group">
                    <button type="button" className="btn btn-secondary" onClick={() => insertList(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                        </svg>
                    </button>
                    <button type="button" className="btn btn-secondary" onClick={() => insertList(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5" />
                            <path d="M1.713 11.865v-.474H2c.217 0 .363-.137.363-.317 0-.185-.158-.31-.361-.31-.223 0-.367.152-.373.31h-.59c.016-.467.373-.787.986-.787.588-.002.954.291.957.703a.595.595 0 0 1-.492.594v.033a.615.615 0 0 1 .569.631c.003.533-.502.8-1.051.8-.656 0-1-.37-1.008-.794h.582c.008.178.186.306.422.309.254 0 .424-.145.422-.35-.002-.195-.155-.348-.414-.348h-.3zm-.004-4.699h-.604v-.035c0-.408.295-.844.958-.844.583 0 .96.326.96.756 0 .389-.257.617-.476.848l-.537.572v.03h1.054V9H1.143v-.395l.957-.99c.138-.142.293-.304.293-.508 0-.18-.147-.32-.342-.32a.33.33 0 0 0-.342.338zM2.564 5h-.635V2.924h-.031l-.598.42v-.567l.629-.443h.635z" />
                        </svg>
                    </button>
                </div>
                <div className="btn-group me-2" role="group">
                    <button type="button" className="btn btn-secondary" onClick={insertTable}>Table</button>
                </div>
                <div className="btn-group me-2" role="group">
                    <button type="button" className="btn btn-secondary" onClick={e => insertTag('p')}>Paragraph</button>
                </div>
                <div className="dropdown  me-2">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Headers
                    </button>
                    <ul className="dropdown-menu">
                        <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); insertTag('h1') }}>Header 1</a></li>
                        <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); insertTag('h2') }}>Header 2</a></li>
                        <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); insertTag('h3') }}>Header 3</a></li>
                        <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); insertTag('h4') }}>Header 4</a></li>
                        <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); insertTag('h5') }}>Header 5</a></li>
                        <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); insertTag('h6') }}>Header 6</a></li>
                    </ul>
                </div>
            </div>
            <div className="border rounded p-3"
                ref={editorRef}
                contentEditable
                style={{ minHeight: '20rem', height: '100%' }}
            >
            </div>
        </div>
    );
};

export default HtmlEditor;
