import { useEffect, useState } from "react"

const ExpressServer = () => {
  const [styleCode, setStyleCode] = useState('')
  const formatColor = { tag: 'blue', quote: 'red', string: 'brown', attr: 'aqua' }

  const code = `const express = require('express')
      const app = express()
      const port = process.env.PORT || 3000

      app.use('/', express.static('public')) // serve static files

      app.get('/', (req, res) => {
        res.send('Hello World!')
        // res.sendFile(__dirname + '/index.html')
      })
      app.get('/about', (req, res) => {
        res.sendFile(__dirname + '/pages/about.html')
      })

      app.listen(port, () => {
        
      })`

  useEffect(() => {
    
    setStyleCode(String(code))

  }, [])
  return (
    <>
      <h1>index.html</h1>
      <pre className="p-3 border text-bg-dark" style={{minHeight:'2rem'} }>
        <code dangerouslySetInnerHTML={{ __html: styleCode } }>
        </code>
      </pre>
    </>
  )
}
export default ExpressServer