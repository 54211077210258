import { useState, useEffect, useRef, useLayoutEffect } from "react"

const Canvas = () => {
  const myCanvas = useRef()
  const [myPics, setMyPics] = useState(null)
  const [ctx, setCtx] = useState(null)
  const [rect, setRect] = useState(null)
  const [penColor, setPenColor] = useState('#000000')
  const [penSize, setPenSize] = useState(1)

  let isDrawing = false;
  let x = 0;
  let y = 0;
  let pen = 'pencil'
  // let pen = 'line'

  useEffect(() => {
    const myPics = myCanvas?.current;
    const ctx = myPics?.getContext("2d");

    setMyPics(myPics)
    setCtx(ctx)
  }, [])

  function debounce(func) {
    var timer;
    return function (event) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(func, 100, event);
    };
  }
  const resizeCanvas = () => {
    const myPics = myCanvas?.current;
    
    myPics.style.width = '100%';
    myPics.style.height = '100%';
    myPics.width = myPics.offsetWidth;
    myPics.height = myPics.offsetHeight;
    
  }

  useLayoutEffect(() => {
    resizeCanvas()
    window.addEventListener("resize", debounce(function (e) {
      // console.log(window.innerWidth, window.innerHeight)
      // resizeCanvas()

    }),false);
  }, []);
  

  const clearCanvas = () => ctx?.clearRect(0, 0, myPics?.width, myPics?.height);
  

  const drawPencil = (ctx, x1, y1, x2, y2) => {
    ctx.beginPath();
    ctx.strokeStyle = penColor;
    ctx.lineWidth = penSize;
    ctx.lineCap = 'round';
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.stroke();
    ctx.closePath();
  }

  const drawLine = (ctx, x1, y1, x2, y2) => {
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.stroke();
  }

  const drawCircle = (e) => {
    const rect = myPics.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    ctx.beginPath();
    ctx.arc(x, y, 10, 0, 2 * Math.PI);
    
    ctx.strokeStyle = penColor;
    ctx.stroke();
    // ctx.fillStyle = 'green';
    // ctx.fill();
  };


  const handleMouseDown = (e) => {
    const rect = myPics.getBoundingClientRect()
    x = e.clientX - rect.left
    y = e.clientY - rect.top

    // console.log(e.nativeEvent.offsetX, e.nativeEvent.offsetY, x, y)
    isDrawing = true;
  }

  const handleMouseMove = (e) => {
    if (isDrawing) {
      const rect = myPics.getBoundingClientRect()
      const cx = e.clientX - rect.left
      const cy = e.clientY - rect.top
      if (pen == 'pencil') {
        drawPencil(ctx, x, y, cx, cy);
        x = cx;
        y = cy;
      }
      
      else if (pen == 'line') {
        clearCanvas();
        drawLine(ctx, x, y, cx, cy);
      }

      // drawCircle(e)

    }
  }

  const handleMouseUp = (e) => {
    if (isDrawing) {
      if (pen == 'pencil') {
        drawPencil(ctx, x, y, e.offsetX, e.offsetY);
      }
      else if (pen == 'line') {
        drawLine(ctx, x, y, e.offsetX, e.offsetY);
      }

      x = 0;
      y = 0;
      isDrawing = false;
    }
  };

  return (
    <>
      <h1>Canvas</h1>
      <div className="d-flex">
        <div className="border p-3 me-3" style={{ minWidth: '14rem' }}>
          <div className="d-flex mb-2 justify-content-between">
            <input type="color" className="me-2" style={{ minHeight: '2.5rem', minWidth: '2.5rem' }} value={penColor} onChange={(e) => setPenColor(e.target.value)} />
            <button className="btn btn-secondary" onClick={() => clearCanvas()}>Clear</button>
          </div>
          <div>
            <input type="range" value={penSize} onChange={(e)=>setPenSize(e.target.value) } />
          </div>
          <div className="mt-2 d-flex justify-content-between">
            <button className="btn me-1 border">&#9998;</button> <button className="btn border me-1">&#9135;</button>
            <button className="btn me-1 border">&#9645;</button> <button className="btn border">&#9675;</button>
          </div>
          <div>
            
          </div>
        </div>
        <div className="flex-grow-1 overflow-hidden">
          <canvas ref={myCanvas} onMouseDown={(e) => { handleMouseDown(e) }} onMouseMove={(e) => { handleMouseMove(e) }} onMouseUp={(e) => { handleMouseUp(e) }} width="100%" height="720" style={{ border: '1px solid #dfdfdf' }}></canvas>
        </div>
      </div>
      
    </>
  )
}
export default Canvas