import { Outlet, Link } from 'react-router-dom'
import Header from './header'
import Footer from './footer'

const BoilerPlatesContainer = ({ children }) => {
    return (
        <>
            <Header />
            <div className="container my-2 d-flex">
                <div className="p-3 border-end me-3" style={{ minWidth: "14rem" }}>
                    <ul className="list-unstyled tree-nav">
                        <li>
                            <h6 className="fw-bold">HTML</h6>
                            <ul className="list-unstyled ps-3">
                                <li><Link className="nav-link" to={"boilerplates/indexhtml"}>Standard index.html</Link></li>
                                <li><Link className="nav-link" to={"boilerplates/pwaindexhtml"}>Progressive Web App</Link></li>
                            </ul>
                        </li>
                        <li>
                            <h6 className="fw-bold mt-3">React JS</h6>
                            <ul className="list-unstyled ps-3 fs-6">
                                <li>
                                    <Link className="nav-link" to={'boilerplates/react'}>React App Setup</Link>
                                    <ul>
                                        <Link className="nav-link" to={'boilerplates/react-package-json'}>package.json</Link>
                                        <Link className="nav-link" to={'boilerplates/react-index-html'}>index.html</Link>
                                        <li>
                                            src
                                            <ul>
                                                <Link className="nav-link" to={'boilerplates/react-main-jsx'}>main.jsx</Link>
                                                <Link className="nav-link" to={'boilerplates/react-app-jsx'}>app.jsx</Link>
                                                <li>
                                                    components
                                                    <ul>
                                                        <li>
                                                            shared
                                                            <ul>
                                                                <Link className="nav-link" to={'boilerplates/react-landing'}>landing.jsx</Link>
                                                                <Link className="nav-link" to={'boilerplates/react-layout'}>layout.jsx</Link>
                                                            </ul>
                                                        </li>
                                                        <li>home.jsx</li>
                                                        <li>about.jsx</li>
                                                        <li>contact.jsx</li>
                                                        <li>terms.jsx</li>
                                                        <li>privacy.jsx</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <Link className="nav-link" to={'boilerplates/react-vite-config'}>vite.config.js</Link>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h6 className="fw-bold mt-3">Node JS</h6>
                            <ul className="list-unstyled ps-3">
                                <li><Link className="nav-link" to={'boilerplates/express-server'}>Express Server</Link></li>
                            </ul>
                        </li>
                        <li>
                            <h6 className="fw-bold mt-3">Tools</h6>
                            <ul className="list-unstyled ps-3">
                                <li><Link className="nav-link" to={'../code-formatter'}>Code Formatter</Link></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="flex-grow-1 overflow-x-auto">
                    <Outlet />
                </div>
            </div>
            <Footer />
        </>
    )
}

export default BoilerPlatesContainer
